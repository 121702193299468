import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { FronteggProvider } from '@frontegg/react';

const contextOptions = {
    baseUrl: 'https://binho.frontegg.com',
    clientId: 'f95e6c08-d3dd-413a-8a87-08f16e4f9ad7'
};

ReactDOM.render(
    <FronteggProvider contextOptions={contextOptions} tracing={true} hostedLoginBox={true} authOptions={{ keepSessionAlive: true }}>
        <App />
    </FronteggProvider>,
    document.getElementById('root')
);
